import React from "react";
// nodejs library that concatenates classes
import classnames from "classnames";

// reactstrap components
import {
  NavItem,
  NavLink,
  Nav,
  TabContent,
  TabPane,
  Row,
  Col
} from "reactstrap";
import PdfViewer from "./PdfViewer";
import Videos from "./Videos";

class CourseResourses extends React.Component {
  state = {
    iconTabs: 1,
  };
  toggleNavs = (e, state, index) => {
    e.preventDefault();
    this.setState({
      [state]: index
    });
  };
  render() {
    return (
      <>
        <Row className="">
          <Col lg="12">
            <div className="nav-wrapper">
              <Nav
                className="nav flex-row"
                id="tabs-icons-text"
                pills
                role="tablist"
              >
                <NavItem>
                  <NavLink
                    aria-selected={this.state.iconTabs === 1}
                    className={classnames("mb-sm-3 mb-md-0 btn-default", {
                      active: this.state.iconTabs === 1
                    })}
                    onClick={e => this.toggleNavs(e, "iconTabs", 1)}
                    role="tab"
                  >
                    <i className="ni ni-single-copy-04 mr-2" />
                    PDF
                  </NavLink>
                </NavItem>
                <NavItem>
                  <NavLink
                    aria-selected={this.state.iconTabs === 2}
                    className={classnames("mb-sm-3 mb-md-0 btn-default", {
                      active: this.state.iconTabs === 2
                    })}
                    onClick={e => this.toggleNavs(e, "iconTabs", 2)}
                    role="tab"
                  >
                    <i className="ni ni-bell-55 mr-2" />
                    Videos
                  </NavLink>
                </NavItem>
              </Nav>
            </div>
           
                <TabContent activeTab={"iconTabs" + this.state.iconTabs}>
                  <TabPane tabId="iconTabs1">
                    <PdfViewer pdfs={this.props.pdfs}/>
                  </TabPane>
                  <TabPane tabId="iconTabs2">
                    <Videos videos={this.props.videos}/>
                  </TabPane>
                </TabContent>
          </Col>
         </Row>
      </>
    );
  }
}

export default CourseResourses;
