import React from "react";
import { Router, Route, Switch, Redirect } from "react-router-dom";
import createHistory from 'history/createBrowserHistory';
import "assets/vendor/nucleo/css/nucleo.css";
import "assets/vendor/font-awesome/css/font-awesome.min.css";
import "assets/scss/argon-design-system-react.scss?v1.1.0";
// import '../node_modules/aos/dist/aos.css';
import SmoothScroll from 'smoothscroll-for-websites';

import Navbar from "./components/Navbars/Navbar";
import Footer from "./components/Footers/SimpleFooter";
import Login from "views/pages/Login";
import Profile from "views/pages/User/Profile";
import UserCourses from "views/pages/User/courses";
import CourseDetails from "views/pages/User/CourseDetails";
// import Register from "views/pages/Register.js";
import passwordReset from "views/pages/PasswordReset";
import Support from "views/pages/support";

export const history = createHistory();

SmoothScroll({ stepSize: 100, animationTime: 400 });
const AppRouter = () => {
	return (

		<Router history={history}>
			<Navbar />
			<Switch>
				{/* <Route
					path="/"
					exact
					render={props => <Profile {...props} />}
				/> */}
				<Route path="/login" exact render={props => <Login {...props} />} />
				<Route path="/reset-password" exact component={passwordReset} />
				<Route
					path="/dashboard"
					exact
					render={props => <Profile {...props} />}
				/>
				<Route
					path="/user/courses"
					exact
					render={props => <UserCourses {...props} />}
				/>
				{/* <Route
						path="/register"
						exact
						render={props => <Register {...props} />}
					/> */}
				<Route
					path="/course/:id"
					exact
					render={props => <CourseDetails {...props} />}
				/>
				<Route
					path="/services/:id"
					exact
					render={props => <CourseDetails {...props} />}
				/>
				<Route
					path="/support"
					exact
					render={props => <Support {...props} />}
				/>
				<Redirect to="/dashboard" />
			</Switch>
			<Footer />
		</Router>
	)
}

export default AppRouter;