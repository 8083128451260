import * as firebase from 'firebase/app';
import "firebase/auth";
import "firebase/firestore";
import "firebase/analytics";
import "firebase/storage"

const firebaseConfig = {
    apiKey: "AIzaSyBuqgwwDD4UliVEKw8BKGPOi4cNeeAdDDw",
    authDomain: "easemytrade1.firebaseapp.com",
    databaseURL: "https://easemytrade1.firebaseio.com",
    projectId: "easemytrade1",
    storageBucket: "easemytrade1.appspot.com",
    messagingSenderId: "606602189322",
    appId: "1:606602189322:web:591abe2429bcc7ffc8ddf3",
    measurementId: "G-0ZL61RPDBY"
  };
// Initialize Firebase
firebase.initializeApp(firebaseConfig);
firebase.analytics();

export const storage = firebase.storage();
export default firebase;

