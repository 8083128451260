import React from "react";
import { useSelector } from "react-redux";
// reactstrap components
import { Card, CardBody} from "reactstrap";
// import { Link } from "react-router-dom";
import moment from "moment";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import states from "../states.json"
const UserDetails = (props) => {
	const auth = useSelector(state => state.auth);
	const user = useSelector(state => state.user);
	const showError = () => toast.error("Access Denied!! Contact Admin");
	return (
		<>	
			<ToastContainer/>
			<Card className="mb-4">
				<CardBody>
					<div className="d-flex justify-content-between">
						<h5 className="text-darker">Personal Details</h5>
						<span className="btn" onClick={showError}><i className="fa fa-edit"></i> EDIT</span>
					</div>
					<div>
						<table className="table table-sm table-borderless">
							<tbody>
								<tr>
									<td className="text-right">Name</td>
									<td className="text-left text-dark ml-2">{user.name || auth.user.displayName}</td>
								</tr>
								{user.dob && 
									<tr>
										<td className="text-right">Date of Birth</td>
										<td className="text-left text-dark ml-2">{moment(user.dob.toDate()).format('Do MMM, YYYY')}</td>
									</tr>
								}
								<tr>
									<td className="text-right">Address</td>
									<td className="text-left text-dark ml-2">{user.address.street && user.address.street +","} {user.address.city && user.address.city+","} {user.address.state && states[user.address.state]+"," } {user.address.pin}</td>
								</tr>
							</tbody>
						</table>
					</div>
				</CardBody>
			</Card>
			<Card className="mb-4">
				<CardBody>
					<div className="d-flex justify-content-between">
						<h5 className="text-darker">Email Addresses</h5>
						<span className="btn" onClick={showError}><i className="fa fa-edit"></i> EDIT</span>
					</div>
					<div>
						<span className="text-right text-nowrap">Email (Primary)</span>
						<span className="text-left text-nowrap text-dark ml-2">{user.email || auth.user.email}</span>
					</div>
				</CardBody>
			</Card>
			{user.phone &&
				<Card className="mb-4">
					<CardBody>
						<div className="d-flex justify-content-between">
							<h5 className="text-darker">Phone</h5>
							<span className="btn" onClick={showError}><i className="fa fa-edit"></i> EDIT</span>
						</div>
						<div>
							<span className="text-right text-nowrap">Mobile (Primary)</span>
							<span className="text-left text-nowrap text-dark ml-2">{user.phone}</span>
						</div>
					</CardBody>
				</Card>
			}
		</>
	)
}
export default UserDetails;