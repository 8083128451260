import React from 'react';
import { Helmet } from 'react-helmet';

const Seo = ({title,description,url}) => {
  return (
      <Helmet>
        <title>{`${title}`}</title>
        <meta name="description" content={description} />
        <meta
          name="robots"
          content="max-snippet:-1, max-image-preview:large, max-video-preview:-1"
        />
        <link rel="canonical" href={`https://easemytrades.com${url}`} />
        <meta property="og:type" content="website" />
        <meta property="og:title" content={`${title} | EaseMyTrade`} />
        <meta property="og:description" content={description} />
        <meta property="og:url" content={`https://easemytrade.com${url}`} />
        <meta property="og:site_name" content="EaseMyTrade" />
        <meta property="og:image" content={require("assets/img/brand/Ease My Trades-Stock Market.png")} />
        <meta property="og:image:secure_url" content={require("assets/img/brand/Ease My Trades-Stock Market.png")} />
        <meta name="twitter:card" content="summary_large_image" />
        <meta name="twitter:description" content={description} />
        <meta name="twitter:title" content={`${title} | EaseMyTrade`} />
        <meta name="twitter:image" content={require("assets/img/brand/Ease My Trades-Stock Market.png")} />
      </Helmet>
  );
}

export default Seo;