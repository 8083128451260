import React, { useState } from "react";
import { useSelector } from 'react-redux';
import {
	Button,
	FormGroup,
	Form,
	Input,
	InputGroupAddon,
	InputGroupText,
	InputGroup,
	Spinner,
	UncontrolledAlert
} from "reactstrap";
import ReCAPTCHA from "react-google-recaptcha";
const LoginForm = ({startLogin}) => {
	const loginError = useSelector(state => state.auth.error);
	const [email, setEmail] = useState('');
	const [password, setPassword] = useState('');
	const [recaptchaValue, setRecaptchaValue] = useState("");
	const [error, setError] = useState(null);
	const [disableButton, setDisabledButton] = useState(false);

	const signInWithEmailAndPasswordHandler =async (event, email, password) => {
		event.preventDefault();
		setDisabledButton(true);
		if (!email) {
			setDisabledButton(false);
			return setError("Please Enter email");
		}
		if (!password) {
			setDisabledButton(false);
			return setError("Please Enter your password");
		}
		if(!recaptchaValue){
			setDisabledButton(false);
			return setError("Captcha verification failed")
		}
		await startLogin(email,password);
		setTimeout(()=>setDisabledButton(false),5000);
	};
	const recaptchaChangeHandler = (value)=>{
		setError(null);
		setDisabledButton(false);
		setRecaptchaValue(value);
	}
	const onChangeHandler = (event) => {
		setError(null);
		setDisabledButton(false);
		const { name, value } = event.currentTarget;
		if (name === 'userEmail') {
			setEmail(value);
		}
		else if (name === 'userPassword') {
			setPassword(value);
		}
	};
	return (
		<>
			{error !== null && <UncontrolledAlert  className="py-2 bg-danger w-100 text-white text-center mb-3">{error}</UncontrolledAlert>}
			{loginError !== null && <UncontrolledAlert className="py-2 bg-danger w-100 text-white text-center mb-3">{loginError}</UncontrolledAlert>}
			<Form role="form" method="POST">
				<FormGroup className="mb-3">
					<InputGroup className="input-group-alternative">
						<InputGroupAddon addonType="prepend">
							<InputGroupText>
								<i className="ni ni-email-83" />
							</InputGroupText>
						</InputGroupAddon>
						<Input placeholder="Email" type="email"
							name="userEmail"
							value={email}
							id="userEmail"
							onChange={event => onChangeHandler(event)} />
					</InputGroup>
				</FormGroup>
				<FormGroup>
					<InputGroup className="input-group-alternative">
						<InputGroupAddon addonType="prepend">
							<InputGroupText>
								<i className="ni ni-lock-circle-open" />
							</InputGroupText>
						</InputGroupAddon>
						<Input
							placeholder="Password"
							type="password"
							autoComplete="off"
							name="userPassword"
							value={password}
							id="userPassword"
							onChange={event => onChangeHandler(event)}
						/>
					</InputGroup>
				</FormGroup>
				<FormGroup className="mt-3">
					<InputGroup className="input-group w-100">
						<ReCAPTCHA
							size="normal"
							// badge="bottomleft"
							name="recaprcha"
							sitekey="6LdlAbMZAAAAAKvFRFzHJhUs5UbdjLQWVBpXWIgc"
							onChange={recaptchaChangeHandler}
							required={true}
						/>
					</InputGroup>
				</FormGroup>
				<div className="text-center">
					<Button
						block
						className="my-2"
						color="default"
						onClick={(event) => { signInWithEmailAndPasswordHandler(event, email, password) }}
						disabled={disableButton}
					>{disableButton && <Spinner size="sm" color="primary" />}{' '}
						Sign in
                          </Button>
				</div>
			</Form>
		</>
	)
}

export default LoginForm;