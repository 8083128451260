import firebase from "../firebase/firebase";
export const setUser = (user) => ({
    type: 'SET_USER',
	user
});
export const unsetUser = () => ({
	type: 'UNSET_USER',
});

export const startSetUser = (uid) => {
    return async (dispatch)=>{
        if (!uid) return null;
        try {
          const userDocument = await firebase.firestore().doc(`users/${uid}`).get();
          // console.log(userDocument)
          return dispatch(setUser({
            uid,
            ...userDocument.data()
          }));
        } catch (error) {
          console.error("Error fetching user");
          return dispatch(setUser({}));
        }
			
	}
}

export const startUnsetUser = () => {
    return (dispatch) => {
        return dispatch(unsetUser())
    };
}
