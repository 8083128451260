import React from "react";
import { useSelector, connect } from "react-redux";
import { history } from "../../../appRouter";
// reactstrap components
import { Card, Container, Row, Col, Spinner, CardBody, Button, NavItem, Nav, NavLink } from "reactstrap";
// import Seo from "../../../components/Seo";
import { startSetCourses } from "actions/course";
import { startSetUser } from "actions/user";
import moment from "moment";
import CourseResourses from "components/CourseResourses";
import { Redirect, Link } from "react-router-dom";
import Seo from "../../../components/Seo";

// import Sidebar from "components/User/Sidebar";

// const mql = window.matchMedia(`(min-width: 800px)`);
const CourseDetails = (props) => {
	const course_id = history.location.pathname.split('/')[2];
	const auth = useSelector(state => state.auth);
	const user = useSelector(state => state.user);
	const courses = useSelector(state => state.courses);
	const batch_ids = useSelector(state => state.user.courses.map(c => c.batch));
	const course = useSelector(state => state.courses.filter(c => c.id === course_id));
	if (!auth.uid) {
		return <Redirect to="/login" />
	}
	// console.log(course_id, course)
	if (auth.uid && !user.uid) {
		props.startSetUser(auth.uid);
	}
	if (batch_ids.length > 0 && courses.length === 0) {
		props.startSetCourses({ batch_ids });
	}
	// const [sidebarOpen,setSidebarOpen]=useState(false);
	// const [sidebarDocked,setSidebarDocked]=useState(mql.matches);
	// const onSetSidebarOpen=(open)=> {
	//   setSidebarOpen(open);
	// }

	return (
		<>
			<Seo
				title="Courses Details"
				description="EaseMyTarde is India's first Stock Market Edu-Platform Providing Stock market courses, training on Stock market, Stock market for beginners, Financial services."
			/>
			<main className="">
				<section className="my-0 py-5 shadow-lg bg-gradient-default">
				</section>
				{/* <Sidebar
				docked={sidebarDocked}
				transitions={true}
				touch={true}
        sidebar={<b>Sidebar content</b>}
        open={sidebarOpen}
        onSetOpen={onSetSidebarOpen}
        styles={{ sidebar: { background: "white",position:'absolute',top:'0',left:'0',bottom:'0' } }}
      >
        <button onClick={() => onSetSidebarOpen(true)}>
          Open sidebar
        </button>
				
      </Sidebar> */}
				<div className="bg-gradient-default d-flex justify-content-center sticky-top">
					<Nav className="nav flex-row  sticky-top" tabs>
						<NavItem>
							<NavLink className="text-white" to="/dashboard" tag={Link} >
								Profile
              </NavLink>
						</NavItem>
						<NavItem>
							<NavLink className="active" to="/user/courses" tag={Link}>
								My Courses
              </NavLink>
						</NavItem>
					</Nav>
				</div>
				<section className="py-4 bg-gradient-white" style={{ position: 'relative' }}>
					<Container>
						<div style={{ minHeight: '50vh' }}>
							{course.length < 1 ? <div className="text-center"><Spinner /></div> :
								<Row className="">
									<Col className="order-lg-3 text-lg-left" md="12">
										{course.map((course, index) => {
											return (
												<div key={index}>
													<Card className="shadow shadow-lg--hover rounded-lg">
														<CardBody>
															<Row>
																<Col lg="7">
																	<h6><i className="ni ni-app text-success mr-2"></i>COURSE</h6>
																	<h5>
																		{course.course.name}
																	</h5>
																	<p>{course.start && moment(course.start.toDate()).format('Do MMM, YYYY')} - {course.end && moment(course.end.toDate()).format('Do MMM, YYYY')} </p>
																</Col>
																<Col lg="5" className="text-lg-right">
																	<Button color="default" outline className="shadow-sm--hover" disabled={!course.class_link} target="_blank" href={course.class_link}>
																		Join Class
                                </Button>
																	<div className="pill py-2">Next Class: <span className="b ">{course.next_class && moment(course.next_class.toDate()).format('Do MMM, hh:mm a')}</span></div>
																</Col>
															</Row>
														</CardBody>
													</Card>
													<br />
													<h6>Resources</h6>
													<CourseResourses videos={course.videos} pdfs={course.course.pdfs}/>
												</div>
											)
										})}
									</Col>
								</Row>}
						</div>
					</Container>
				</section>
			</main>
			<br/>
			<br/>
			<br/>
			<br/>
			<br/>
			<br/>
		</>
	);

}
const mapDispatchToProps = (dispatch) => {
	return {
		startSetCourses: (batch_ids) => dispatch(startSetCourses(batch_ids)),
		startSetUser: (uid) => dispatch(startSetUser(uid))
	}
}
export default connect(undefined, mapDispatchToProps)(CourseDetails);
