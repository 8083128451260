import React from "react";
import { useSelector } from "react-redux";
// reactstrap components
import { Card,CardBody } from "reactstrap";
// import { Link } from "react-router-dom";

const UserPhoto = (props) => {
	const auth = useSelector(state => state.auth);
	console.log(auth)
	const user = useSelector(state => state.user);
	return (
		<>
		<Card className="shadow-sm mb-4">
			<CardBody>
				<div className="mt-2 text-center">
					<img style={{ width:'80%',maxWidth: '100px' }}
						alt="..."
						className="rounded-circle shadow"
						src={auth.user.photoURL || require('assets/img/theme/avatar.png')}
					/>
					<h6 className="mt-2">{user.name || auth.user.displayName}</h6>
				</div>
			</CardBody>
		</Card>
		</>
	)
}
export default UserPhoto;