import React from "react";
// reactstrap components

import {
  Container,
  Row,
  Col
} from "reactstrap";
import Seo from "../../components/Seo";
import ChatBox from "components/chatbox";

const Support = (props) => {
  return (
    <>
    <Seo 
      title="Support | Dashboard" 
    />
    <main>
      <section className="section section-shaped section-lg" style={{backgroundImage: 'linear-gradient(135deg, #998af0 0%, #764ba2 100%)'}}>
        
        <Container className="pt-3">
          <Row className="justify-content-center">
            <Col lg="5">
              <ChatBox/>
            </Col>
          </Row>
        </Container>
      </section>
    </main>
    </>
  );
}

export default Support;