import React, { useState } from "react";
import { Link } from "react-router-dom";
import firebase from "../../firebase/firebase";
import {
  Button,
  Card,
  // CardHeader,
  CardBody,
  FormGroup,
  Form,
  Input,
  InputGroupAddon,
  InputGroupText,
  InputGroup,
  Container,
  Row,
  Col,
  Spinner,
  UncontrolledAlert
} from "reactstrap";
import Seo from "../../components/Seo";
import ReCAPTCHA from "react-google-recaptcha";

const backgroundStyle = {
  background: `linear-gradient(rgba(0,0,0,0.4), rgba(0,0,0,0.4)), url(${require('assets/img/theme/stock market.jpg')})`,
  backgroundPosition: 'center',
  backgroundSize: 'cover',
  backgroundRepeat: 'no-repeat',
}
const PasswordReset = () => {
  const [email, setEmail] = useState("");
  const [recaptchaValue, setRecaptchaValue] = useState("");
  const [emailHasBeenSent, setEmailHasBeenSent] = useState(false);
  const [error, setError] = useState(null);
  const [disableButton, setDisabledButton] = useState(false);
  const onChangeHandler = event => {
    setError(null);
    setDisabledButton(false);
    const { name, value } = event.currentTarget;
    if (name === "userEmail") {
      setEmail(value);
    }
  };
  const recaptchaChangeHandler = (value)=>{
    setError(null);
    setDisabledButton(false);
    setRecaptchaValue(value);
  }
  const sendResetEmail = event => {
    event.preventDefault();
    setDisabledButton(true);
    if(!email){
      setDisabledButton(false);
      return setError("Enter email")
    }
    if(!recaptchaValue){
      setDisabledButton(false);
      return setError("Captch validation failed")
    }
    // console.log(recaptchaValue)
    firebase.auth()
      .sendPasswordResetEmail(email)
      .then(() => {
        setDisabledButton(false);
        setEmailHasBeenSent(true);
        setTimeout(() => {setEmailHasBeenSent(false)}, 3000);
      })
      .catch((error) => {
        // console.log(error)
        setDisabledButton(false);
        setError(error.code ? error.code.split('/')[1].replace(/-/g, ' ') : "Error resetting password");
      });
  }
  return (
    <>
    <Seo 
      title="Reset Password | EaseMyTrade" 
      description="EaseMyTarde is India's first Stock Market Edu-Platform Providing Stock market courses, training on Stock market, Stock market for beginners, Financial services."
      url="/reset-password"
    />
    <main>
      <section className="section section-shaped section-lg" style={backgroundStyle}>
        <div className="shape shape-style-1 bg-gradient-white">
          {/* <span />
              <span />
              <span />
              <span />
              <span />
              <span />
              <span />
              <span /> */}
        </div>
        <Container className="pt-lg-5 pt-sm-5">
          <Row className="justify-content-center">
            <Col lg="5">
              <Card className="bg-secondary shadow border-0">
                <CardBody className="px-lg-5 py-lg-2">
                  <div className="text-center text-muted mb-4">
                    <small>Reset your Password</small>
                  </div>
                  {emailHasBeenSent && (
                    <UncontrolledAlert className="py-2 bg-success w-100 text-white text-center mb-3">
                      An email has been sent to you!
                    </UncontrolledAlert>
                  )}
                  {error !== null && (
                    <UncontrolledAlert className="py-2 bg-danger w-100 text-white text-center mb-3">
                      {error}
                    </UncontrolledAlert>
                  )}
                  <Form role="form" method="POST">
                    <FormGroup className="mb-3">
                      <InputGroup className="input-group-alternative">
                        <InputGroupAddon addonType="prepend">
                          <InputGroupText>
                            <i className="ni ni-email-83" />
                          </InputGroupText>
                        </InputGroupAddon>
                        <Input 
                          type="email"
                          name="userEmail"
                          id="userEmail"
                          value={email}
                          placeholder="Enter your email"
                          onChange={event => onChangeHandler(event)} />
                      </InputGroup>
                    </FormGroup>
                    <FormGroup className="">
                      <InputGroup className="input-group">
                        <ReCAPTCHA
                          // size="invisible"
                          // badge="bottomleft"
                          sitekey="6LdlAbMZAAAAAKvFRFzHJhUs5UbdjLQWVBpXWIgc"
                          onChange={recaptchaChangeHandler}
                          required={true}
                        />
                      </InputGroup>
                    </FormGroup>
                    <div className="text-center">
                      <Button
                        className="w-100"
                        color="default"
                        onClick={(event) => { sendResetEmail(event) }}
                        disabled={disableButton}
                      >{disableButton && <Spinner size="sm" color="primary" />}{' '}
                        Submit
                      </Button>
                    </div>
                  </Form>
                  <Row>
                    <Col className="text-center py-2">
                    <Link
                      to ="/login"
                      className="my- text-center block"
                    >
                    &larr; back to sign in page
                  </Link>
                    </Col>     
                  </Row>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </section>
    </main>
    
    </>
  );
};
export default PasswordReset;