import {createStore, combineReducers, applyMiddleware, compose} from 'redux';
import thunk from "redux-thunk";
import authReducer from "../reducers/auth";
import userReducer from 'reducers/userReducer';
import courseReducer from 'reducers/courseReducer';

const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENTION_COMPOSE__ || compose;
export default ()=>{
    const store = createStore(
        combineReducers({
            auth: authReducer,
            user:userReducer,
            courses:courseReducer,
        }),
        composeEnhancers(applyMiddleware(thunk))
    );
    return store;
}