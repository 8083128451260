import React from "react";
import { useSelector, connect } from "react-redux";
// reactstrap components
import {Container, Row, Col, Spinner,Nav, NavItem, NavLink } from "reactstrap";
import Seo from "../../../components/Seo";
import { startSetUser } from "actions/user";
import UserPhoto from "components/User/photo";
import UserDetails from "components/User/UserDetails";
import { Link } from "react-router-dom";
import CoursesEnrolled from "components/User/coursesEnrolled";
// import LogoutButton from "components/LogoutButton";
const Profile = (props) => {
  const auth = useSelector(state => state.auth);
  const user = useSelector(state => state.user);
  if (auth.uid && !user.uid) {
    props.startSetUser(auth.uid);
  }
  // console.log(user)
  return (
    <>
      <Seo
        title="Dashboard"
        description="EaseMyTarde is India's first Stock Market Edu-Platform Providing Stock market courses, training on Stock market, Stock market for beginners, Financial services."
        url="/dashboard"
      />
      <main className="">
        <section className="py-5 shadow-lg bg-gradient-default">
        </section>
        <div className="bg-gradient-default d-flex justify-content-center sticky-top" style={{zIndex:'2'}}>
          <Nav className="nav flex-row  sticky-top" tabs>
            <NavItem>
              <NavLink className="active" to="/dashboard" tag={Link} >
                Profile
              </NavLink>
            </NavItem>
            <NavItem>
              <NavLink className="text-white" to="/user/courses" tag={Link}>
                My Courses
              </NavLink>
            </NavItem>
          </Nav>
        </div>
        <section className="py-4 bg-gradient-white">
          <Container>
            <div style={{ minHeight: '50vh' }}>
              {!user.uid ? <div className="text-center"><Spinner /></div> :
                <Row className="">
                  <Col className="order-lg-2 text-center" md="3">
                    <UserPhoto/>
                    <div className="d-none d-md-block">
                      <CoursesEnrolled/>
                      {/* <LogoutButton color="default" classes=""/> */}
                    </div>
                  </Col>

                  <Col className="order-lg-3 text-lg-left" md="9">
                    <UserDetails/>
                    <div className="d-md-none">
                      <CoursesEnrolled/>
                    </div>
                  </Col>
                </Row>}
            </div>
          </Container>
        </section>
        <br/>
        <br/>
      </main>
    </>
  );

}
const mapDispatchToProps = (dispatch) => {
  return {
    startSetUser: (uid) => dispatch(startSetUser(uid))
  }
}
export default connect(undefined, mapDispatchToProps)(Profile);
