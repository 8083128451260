import { type } from "jquery";
import firebase from "../firebase/firebase";
export const login = ({ uid, user }) => ({
	type: 'LOGIN',
	uid,
	user
});
export const loginError = (error) => ({
	type: 'LOGIN_ERROR',
	error
});
export const signout = () => ({
	type: 'SIGNOUT'
});
export const unsetCourses = () =>({
	type:'UNSET_COURSES'
});

export const startLogin = (email, password) => {
	return (dispatch) => {
		return firebase.auth().signInWithEmailAndPassword(email, password).then((res) => {
			dispatch(login({
				uid: res.user.uid,
				user: {
					email: res.user.email,
					displayName: res.user.displayName,
					photoURL: res.user.photoURL
				}
			}))
			dispatch(loginError(null))
		}).catch(error => {
			// console.log(error)
			dispatch(loginError(error.code.split('/')[1].replace(/-/g, ' ').toUpperCase()))
		});
	}
}

export const startSignOut = () => {
	return (dispatch) => {
		return firebase.auth().signOut().then(() => {
			dispatch(unsetCourses());
			dispatch(signout());
		});
	};
}