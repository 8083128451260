import React from "react";
import { connect } from "react-redux";
import { startSignOut } from "../actions/auth";
import {DropdownItem} from 'reactstrap'
// import { Button } from "reactstrap";
const LogoutButton = (props) => {
    return (
        // <Button
        //     color={props.color}
        //     // href="#logout"
        //     onClick={props.startSignOut}
        //     size="md"
        //     className={props.classes}
        //     block
        // >
        
        // Logout {props.children}
        // </Button>
        <DropdownItem className="py-0" onClick={props.startSignOut}>
            <span color="default" classes="nav-link-inner--text text-danger"><i className="fa fa-sign-out"> </i> Logout </span>
        </DropdownItem>
    );

}
const mapDispatchToProps = (dispatch) => ({
    startSignOut: () => dispatch(startSignOut())
});
export default connect(undefined, mapDispatchToProps)(LogoutButton);
