import firebase from "../firebase/firebase";
export const setCourses = ({courses}) => ({
  type: 'SET_COURSES',
  courses
});
export const unsetUser = () => ({
  type: 'UNSET_COURSES',
});

async function getCourse(ref){
  let courseSnapshot =await ref.get();
  let course = courseSnapshot.data();
  return course;
}

async function getBatchById (ids) {
  return await firebase.firestore().collection('batches').where(firebase.firestore.FieldPath.documentId(),'in',ids).get().then(async querySnapshot =>{
    const promises= querySnapshot.docs.map(async snapshot=>{
      let batch =snapshot.data();
      batch.id=snapshot.id;
      batch.course = await getCourse(batch.course);
      return batch;
    })
    return Promise.all(promises);
  });
}

export const startSetCourses = ({ batch_ids }) => {
  return async (dispatch) => {
    if (batch_ids.length<1) return null; 
    try {
      const courses = await getBatchById(batch_ids)
      return dispatch(setCourses({
        courses
      }));
    } catch (error) {
      console.error("Error fetching courses");
      return dispatch(setCourses({courses:[]}));
    }
  }
}

export const startUnsetCourses = () => {
  return (dispatch) => {
    return dispatch(unsetUser())
  };
}
