import React, { useState, useEffect } from "react";
import { useSelector } from 'react-redux';
import { Link } from "react-router-dom";
// JavaScript plugin that hides or shows a component based on your scroll
// import Headroom from "headroom.js";
// reactstrap components
import {
  Button,
  UncontrolledCollapse,
  DropdownMenu,
  DropdownItem,
  DropdownToggle,
  UncontrolledDropdown,
  // Media,
  NavbarBrand,
  Navbar,
  NavItem,
  NavLink,
  Nav,
  Container,
  Row,
  Col,
  // UncontrolledTooltip
} from "reactstrap";
import LogoutButton from "components/LogoutButton";
//5e72e4

const TopNavbar = () => {
  const uid = useSelector(state => state.auth.uid);
  useEffect(() => {
    // let headroom = new Headroom(document.getElementById("navbar-main"));
    // initialise
    // headroom.init();
  });
  const [collapseClasses, setCollapseClasses] = useState("");

  const onExiting = () => {
    setCollapseClasses("collapsing-out")
  };

  const onExited = () => {
    setCollapseClasses("")
  };

  const hideMenu = () => {
    document.getElementById('navbar_global').click();
  }
  // let user = useContext(UserContext);
  return (
    <>
      <header className="header-global" style={{ zIndex: '999' }}>
        <Navbar
          className="navbar-main navbar-transparent navbar-light headroom shadow-sm"
          expand="sm"
          id="navbar-main"
          style={{padding:'0.2rem !important'}}
        >
          <Container>
            <NavbarBrand className="mr-lg-5" to="/" tag={Link}>
              {/* <img
                alt="..." style={{ height: "50px" }} className="logo-white"
                src={require("assets/img/brand/EMT_Stock Marketing Course.png")}
              /> */}
              <img
                alt="..." style={{ height: "50px" }} className="logo-dark"
                src={require("assets/img/brand/EMT_Stock Market.png")}
              />
            </NavbarBrand>
            <button className="navbar-toggler" id="navbar_global">
              <span className="navbar-toggler-icon" />
            </button>
            <UncontrolledCollapse
              toggler="#navbar_global"
              navbar
              className={collapseClasses}
              onExiting={onExiting}
              onExited={onExited}
            >
              <div className="navbar-collapse-header">
                <Row>
                  <Col className="collapse-brand" xs="6">
                    <Link to="/">
                      <img
                        alt="..."
                        src={require("assets/img/brand/EMT_Stock Market.png")}
                      />
                    </Link>
                  </Col>
                  <Col className="collapse-close" xs="6">
                    <button className="navbar-toggler" id="navbar_global">
                      <span />
                      <span />
                    </button>
                  </Col>
                </Row>
              </div>
              <Nav className="align-items-lg-center ml-lg-auto" navbar>
                <NavItem >
                  {/* <NavLink to="/" tag={Link} onClick={hideMenu}>
                    <span className="nav-link-inner--text font-weight-bold">Home</span>
                  </NavLink> */}
                  <a target="_blank" rel="noopener noreferrer" className="nav-link-inner--text font-weight-bold" href="//easemytrades.com">Home</a>
                </NavItem>
                

                

                {uid != null ?
                  
                  <Nav className="navbar-nav-hover align-items-lg-center" navbar>
                  <NavItem>
                  <NavLink href="/dashboard">
                    <span className="nav-link-inner--text font-weight-bold">Profile</span>
                  </NavLink>
                </NavItem>
                
                    <UncontrolledDropdown direction="down" nav>
                      <DropdownToggle size="sm" caret nav>
                        <span className="nav-link-inner--text font-weight-bold">Account</span>
                      </DropdownToggle>
                      <DropdownMenu size="sm">

                        <Link to="/user/courses" tag={Link} onClick={hideMenu}>
                          <DropdownItem size="sm" className="py-1">
                            <span className="nav-link-inner--text text-dark"><i className="fa fa-book"></i> My Courses</span>
                          </DropdownItem>
                        </Link>

                        <DropdownItem divider />
                          <LogoutButton/>
                      </DropdownMenu>
                    </UncontrolledDropdown>
                  </Nav>
                  : <NavItem>
                    <Button
                      to="/login"
                      size="sm"
                      className="mb-2"
                      tag={Link}
                      onClick={hideMenu}
                    >
                      <span className="nav-link-inner--text ml-1 text-nowrap">Sign in</span>
                    </Button> </NavItem>
                }
              </Nav>
            </UncontrolledCollapse>
          </Container>
        </Navbar>
      </header>
    </>
  );
}

export default TopNavbar;
