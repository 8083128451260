import React from "react";
import { useSelector, connect } from "react-redux";
// reactstrap components
import { Card, Container, Row, Col, Spinner, CardBody, Button, Nav, NavItem, NavLink } from "reactstrap";
import Seo from "../../../components/Seo";
import { startSetCourses } from "actions/course";
import { startSetUser } from "actions/user";
import moment from "moment";
import UserPhoto from "components/User/photo";
import { Link } from "react-router-dom";


const UserCourses = (props) => {
	const auth = useSelector(state => state.auth);
	const user = useSelector(state => state.user);
	const courses = useSelector(state => state.courses);
	console.log(courses)
	const batch_ids = useSelector(state => state.user.courses.map(c => c.batch));
	// console.log(batch_ids, courses)
	if (auth.uid && !user.uid) {
		props.startSetUser(auth.uid);
	}
	if (batch_ids.length > 0 && courses.length === 0) {
		props.startSetCourses({ batch_ids });
	}
	// console.log(user)
	return (
		<>
			<Seo
				title="Courses Enrolled"
				description="EaseMyTarde is India's first Stock Market Edu-Platform Providing Stock market courses, training on Stock market, Stock market for beginners, Financial services."
				url="/user/courses"
			/>
			<main className="">
				<section className="my-0 py-5 shadow-lg bg-gradient-default">
				</section>
				<div className="bg-gradient-default d-flex justify-content-center sticky-top">
					<Nav className="nav flex-row  sticky-top" tabs>
						<NavItem>
							<NavLink className="text-white" to="/dashboard" tag={Link} >
								Profile
              </NavLink>
						</NavItem>
						<NavItem>
							<NavLink className="active" to="/user/courses" tag={Link}>
								My Courses
              </NavLink>
						</NavItem>
					</Nav>
				</div>
				<section className="py-4 bg-gradient-white">
					<Container>
						<div style={{ minHeight: '50vh' }}>
							{!user.uid ? <div className="text-center"><Spinner /></div> :
								<Row className="">
									<Col className="order-lg-2 text-center" md="3">
										<div className="d-none d-md-block">
											<UserPhoto />
										</div>
									</Col>
									<Col className="order-lg-3 text-lg-left" md="9">
										{courses.map((course, index) => {
											return (
												<Card className="shadow-sm shadow-lg--hover rounded" key={index}>
													<CardBody>
														<Row>
															<Col lg="7">
																<h6><i className="ni ni-app text-success mr-2"></i>COURSE</h6>
																<h5>
																	{course.course.name}	
																</h5>
																<p>{course.start && moment(course.start.toDate()).format('Do MMM, YYYY')} - {course.end && moment(course.end.toDate()).format('Do MMM, YYYY')} </p>
															</Col>
															<Col lg="5" className="text-lg-right">
																<Button color="default" outline className="shadow-sm--hover" to={`/course/${course.id}`} tag={Link}>
																	View
                                </Button>
																<Button color="default" disabled={!course.class_link} className="shadow-sm--hover" target="_blank" href={course.class_link}>
																	Join Class
                                </Button>
																<div className="pill py-2">Next Class: <span className="b ">{course.next_class && moment(course.next_class.toDate()).format('Do MMM, hh:mm a')}</span></div>
															</Col>
														</Row>
													</CardBody>
												</Card>
											)
										})}
									</Col>
								</Row>}
						</div>
					</Container>
				</section>
			</main>
			<br/>
			<br/>
		</>
	);

}
const mapDispatchToProps = (dispatch) => {
	return {
		startSetCourses: (batch_ids) => dispatch(startSetCourses(batch_ids)),
		startSetUser: (uid) => dispatch(startSetUser(uid))
	}
}
export default connect(undefined, mapDispatchToProps)(UserCourses);
