const coursesDefault =[]
export default (state = coursesDefault, action) => {
    switch (action.type) {
      case 'SET_COURSES':
        return action.courses
      case 'UNSET_COURSES':
        return coursesDefault;
      default:
        return state;
    }
  };
