import React, { useState } from "react";
import { FormGroup, InputGroup, Input } from "reactstrap";
import ReactPlayer from 'react-player'

const Videos = ({videos}) => {
    const [video, setVideo] = useState('')
    const onChangeHandler = (e) => {
        setVideo(e.target.value);
    }
    // let file = require(`assets/pdf/${pdf}`)
    return videos.length>0? (
        <div>
            <FormGroup>
                <InputGroup className="input-group-alternative shadow border">
                    <Input type="select" name="video" id="video" value={video} onChange={event => onChangeHandler(event)}>
                        <option value='' >select Video</option>
                        {videos.map((v, i) => {
                            return <option value={v.url} key={i}>{v.name}</option>
                        })}
                    </Input>
                </InputGroup>
            </FormGroup>
            {video && <ReactPlayer
                // Disable download button
                config={{ file: { attributes: { controlsList: 'nodownload' } } }}
                // Disable right click
                onContextMenu={e => e.preventDefault()}
                // Your props
                url={video}
                className="react-player"
                controls
                width="100%"
                height="100%"
                />
            }
        </div>
    ):<p>Videos will be uploaded after classes starts.</p>
}


export default Videos;