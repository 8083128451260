import React from "react";
import { Redirect,Link } from "react-router-dom";
import { useSelector } from "react-redux";

import {connect } from "react-redux";
import {startLogin} from "../../actions/auth";
// reactstrap components
import {
  Card,
  Container,
  CardBody,
  Row,
  Col
} from "reactstrap";
import Seo from "../../components/Seo";
import LoginForm from "components/LoginForm";

const backgroundStyle = {
  background: `linear-gradient(rgba(0,0,0,0.4), rgba(0,0,0,0.4)), url(${require('assets/img/theme/b3.jpg')})`,
  backgroundPosition: 'center',
  backgroundSize: 'cover',
  backgroundRepeat: 'no-repeat',
}

const Login = (props) => {
  const user = useSelector(state => state.auth.uid);
  if(!!user){
    return <Redirect to="dashboard"/>
  }
  const onSubmit = (email,password) => {
    props.startLogin(email,password)
  };
  return (
    <>
    <Seo 
      title="Login | EaseMyTrade" 
      description="EaseMyTarde is India's first Stock Market Edu-Platform Providing Stock market courses, training on Stock market, Stock market for beginners, Financial services."
      url="/login"
    />
    <main className="h-100">
      <section className="section section-shaped section-lg" style={backgroundStyle}>
        <div className="shape shape-style-1 bg-gradient-white">
          {/* <span />
              <span />
              <span />
              <span />
              <span />
              <span />
              <span />
              <span /> */}
        </div>
        <Container className="pt-5">
          <Row className="justify-content-center">
            <Col lg="5">
              <Card className="bg-secondary shadow border-0">
                {/* <CardHeader className="bg-white pb-3">
                  <div className="text-muted text-center mb-2">
                    <small>Sign in with</small>
                  </div>
                  <div className="btn-wrapper text-center px-4 px-sm-0">
                    <Button
                      className="btn-neutral btn-icon w-100"
                      color="default"
                      href="/"
                      onClick={e => { e.preventDefault(); signInWithGoogle(); }}
                    >
                      <span className="btn-inner--icon mr-1">
                        <img
                          alt="..."
                          src={require("assets/img/icons/common/google.svg")}
                        />
                      </span>
                      <span className="btn-inner--text">Google</span>
                    </Button>
                  </div>
                </CardHeader> */}
                <CardBody className="px-lg-5 py-lg-2">
                  {/* <div className="text-center text-muted mb-4">
                    <small>Or sign in with credentials</small>
                  </div> */}
                  <br/>
                  <LoginForm startLogin={onSubmit}/>
                  <Row className="my-3 text-center">
                    <Col xs="12">
                      <Link
                        className=""
                        to="/reset-password"
                      >
                        <small>Forgot password?</small>
                      </Link>
                    </Col>
                    {/* <Col className="text-right" xs="6">
                      <Link
                        className=""
                        to="/register"
                      >
                        <small>Create new account</small>
                      </Link>
                    </Col> */}
                  </Row>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </section>
    </main>
    </>
  );
}

// const mapStateToProps=()=>{
//   return {}
// }
const mapDispatchToProps = (dispatch) => ({
  startLogin:(email,password)=>dispatch(startLogin(email,password)),
});
export default connect(undefined, mapDispatchToProps)(Login);

// export default Login;
