const userDefault ={
    name:'',
    email:'',
    phone:'',
    dob:'',
    address:'',
    courses:[]
}
export default (state = userDefault, action) => {
    switch (action.type) {
      case 'SET_USER':
        return {
            ...state,
            ...action.user
        };
      case 'UNSET_USER':
        return userDefault;
      default:
        return state;
    }
  };
