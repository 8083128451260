import React from "react";
import { useSelector } from "react-redux";
// reactstrap components
import { Card, CardBody, CardFooter } from "reactstrap";
import { Link } from "react-router-dom";

const CoursesEnrolled = (props) => {
	const user = useSelector(state => state.user);
	return (
		<>
		<Card className="shadow-sm mb-4">
			<CardBody className=" py-1">
				<div className="text-center">
					<h4 className="text-dark">{user.courses.length}</h4>
					<div>Courses Enrolled</div>
				</div>
			</CardBody>
			<CardFooter className="py-1">
				<Link className="text-default" to="/user/courses">Go To courses </Link>
			</CardFooter>
		</Card>
		</>
	)
}
export default CoursesEnrolled;