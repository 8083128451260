import React from "react";
import AppRouter from "appRouter";
import firebase from "./firebase/firebase";
import { store } from "index";
import { history } from "./appRouter";
import { unsetUser } from "./actions/user";
import { login, signout } from "./actions/auth";
import Loading from "components/Loading";

let loading=false;

const App = () => {
	return loading?<Loading/>:<AppRouter />
}

firebase.auth().onAuthStateChanged((user) => {
	if (user) {
		const auth = store.getState().auth;
		// console.log(store.getState())
		if (auth.uid === null) {
			store.dispatch(login({
				uid: user.uid,
				user: {
					email: user.email,
					displayName: user.displayName,
					photoURL: user.photoURL
				}
			}));
		}
		if (history.location.pathname === '/login') {
			history.push('/dashboard');
		}
	} else {
		// console.log('signout')
		if (history.location.pathname === '/dashboard' || history.location.pathname === '/user/courses' || history.location.pathname === '/support') {
			history.push('/login');
		}
		store.dispatch(signout());
		store.dispatch(unsetUser())
	}
})
export default App;