import React, { useState } from "react";
import { FormGroup, InputGroup, Input,Button } from "reactstrap";
// import Iframe from "./Iframe";

const PdfViewer = ({pdfs}) => {
    // const pdfs = props.pdfs || [];
    // console.log(pdfs)
    const [pdf, setPdf] = useState('')
    const onChangeHandler = (e) => {
        setPdf(e.target.value);
    }

return (
    <div>
        <FormGroup>
            <InputGroup className="input-group-alternative shadow border">
                <Input type="select" name="pdf" id="pdf" value={pdf} onChange={event => onChangeHandler(event)}>
                    <option value={""} >select</option>
                    {pdfs.map((p, i) => {
                        return <option value={p.link} key={i}>{p.name}</option>
                    })}
                </Input>
            </InputGroup>
        </FormGroup>
        <object data={pdf} type="application/pdf" style={{width:'100%', height:'90vh'}}>
            <p>Your Web Browser doesn't have a PDF plugin.
            Instead you can <a target="_blank" rel="noopener noreferrer" href={pdf}><Button>download</Button></a> the file. </p>
        </object>
    </div>
)
}

export default PdfViewer;