import React from "react";
import ReactDOM from "react-dom";
import App from './app'
import * as serviceWorker from './serviceWorker';
import { Provider } from "react-redux";
import configureStore from "./store/configureStore";

export const store = configureStore();

ReactDOM.render(
  <Provider store={store}>
    <App />
  </Provider>,
  document.getElementById("root")
);
// document.addEventListener('contextmenu', event => event.preventDefault());
serviceWorker.unregister();
