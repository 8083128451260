const authDefault={
  uid:null,
  user:{
    email:'',
    displayName:'',
    photoURL:''
  },
  error:null,
  loading:false
}

export default (state = authDefault, action) => {
  switch (action.type) {
    case 'LOGIN':
      return {
        ...state,
        uid: action.uid,
        user: action.user
      };
    case 'LOGIN_ERROR':
      return {
        ...state,
        error: action.error
      };
    case 'SIGNOUT':
      return authDefault;
    default:
      return state;
  }
};
